.home{
    display: flex;
    font-family: "Varela" , sans-serif;
    background: #fff url("../../assets/images/background-home.svg") no-repeat;
    padding-top: 20px;
    color: black;
}
.home-image-slider{
    position: relative;
    text-align: center;
    vertical-align: middle;
    margin: 20px 20px 0;
    height: 300px;
    border: 3px solid black;
    overflow: hidden;
    border-radius: 10px;
}
.homeImage{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.home-image-slider h1{
    color: white;
    position: absolute;
    z-index: 100;
    font-size: 100px;
    -webkit-text-stroke: 3px black;
}
.home-content{
    display: block;
}

.home-presentation{
    padding: 0 20px;
}

.home-presentation p{
    margin-right: 20%;
    color: white;
}

.home-presentation h2{
    color: white;
    margin: 20px 0;
    text-decoration: 2px underline;
    text-underline-offset: 5px;
}

.home-preview{
    margin-top: 70px;
    padding: 0 20px;
}
.home-preview h2{
    color: white;
}

.home-preview-content{
    position: relative;
    display: block;
    height: auto;
    max-width: 600px;
    margin: 40px auto;
    background-color: lightgrey;
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
}

.home .home-preview-content a{
    text-decoration: none;
    display: flex;
    right: 10px;
    top : 10px;
    position: absolute;
    color: navy;
    font-size: medium;
    cursor: alias;
}


@media (max-width: 500px){
    .homeImage{
        height: 100%;
        width: unset;
    }
    .home-image-slider h1{
        font-size: 70px;
    }

    .home-presentation p{
        margin-right: 0;
    }
}

@media (max-width: 800px){
    .home{
        position: relative;
        display: block;
    }

}

