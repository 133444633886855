.journal{
    height: 100%;
    font-family: "Varela" , sans-serif;
}

.journal .Post .BlogLabel{
    text-decoration: none;
    display: flex;
    right: 10px;
    top : 7px;
    position: absolute;
    color: navy;
    font-size: medium;
    cursor: alias;
}

.Post{
    position: relative;
    display: block;
    height: auto;
    max-width: 600px;
    margin: 40px auto;
    background-color: lightgrey;
    padding: 20px;
    border-radius: 10px;

}

.postImg{
    margin-top: 20px;
    position: relative;
    max-height: 250px;
    overflow: hidden;
    max-width: calc(100vw - 45px);
    border: 2px solid black;
    border-radius: 10px;
    cursor: pointer;
}
.PostContainer{
    height: 300px;
    width: 300px;
    border: 3px solid black;
    margin: 20px 0;
}

.journal .header{
    text-align: center;
    margin-top: 20px;
}





