.write {
  padding-top: 50px;
  margin-left: 20px;
}
#writeLoader{
  visibility: hidden;
  z-index: 100;
  height: 150px;
  width: 300px;
  position: absolute;
  border: 3px solid black;
  background-color: lightgrey;
  border-radius: 10px;
  text-align: center;
  margin-left: calc(50vw - 150px);
}
#writePopupSuccess{
  visibility: hidden;
  z-index: 100;
  height: 150px;
  width: 300px;
  position: absolute;
  border: 3px solid black;
  background-color: lightgreen;
  border-radius: 10px;
  text-align: center;
  margin-left: calc(50vw - 150px);
}
#writePopupError{
  visibility: hidden;
  z-index: 100;
  height: 150px;
  width: 300px;
  position: absolute;
  border: 3px solid black;
  background-color: lightcoral;
  border-radius: 10px;
  text-align: center;
  margin-left: calc(50vw - 150px);
}
.writeImg {
  width: 70vw;
  height: 250px;
  border-radius: 10px;
  object-fit: cover;
}

.writeForm {
  position: relative;
}

.writeFormGroup {
  align-items: center;
}

.writeIcon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: rgb(121, 118, 118);
  cursor: pointer;
}

.writeInput {
  font-size: 30px;
  border: none;
  padding: 20px;
  width: 70vw;
}

.writeInput:focus {
  outline: none;
}
.keyInput {
  font-size: 10px;
  border: none;
  padding: 20px;
  width: 70vw;
}

.keyInput:focus {
  outline: none;
}
.writeText {
  font-size: 20px;
  height: 30vh;
}

.writeSubmit {
  position: absolute;
  top: 20px;
  right: 50px;
  color: white;
  background-color: teal;
  padding: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

@media (max-width: 400px){
  .writeInput{
    font-size: 20px;
  }
  .writeText{
    font-size: 12px;
  }
}