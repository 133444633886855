.sidebar {
  flex: 3;
  padding-bottom: 30px;
  background-color: #fdfbfb;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  padding-top: 20px;
  border: 1px solid lightgrey;
  max-width: 300px;
}
.sidebar-content{
  width: 250px;
}

.sidebarItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.sidebarTitle {
  margin: 10px;
  padding: 5px;
  width: 40%;
  border-top: 2px solid darkred;
  border-bottom: 2px solid darkred;
  font-family: "Varela Round", sans-serif;
  font-size: 12px;
  color: #222;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  transition: .3s ease-in-out;
}

.sidebarTitleNav{
  margin: 10px;
  padding: 5px;
  width: 80%;
  border-top: 1px solid #a7a4a4;
  border-bottom: 1px solid #a7a4a4;
  font-family: "Varela Round", sans-serif;
  font-size: 12px;
  color: #222;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}

.sidebarItem p{
  padding: 30px;
  width: 250px;
  text-align: center;
}


.sidebarTitle:hover {
  width: 80%;
}
.sidebar-link{
  text-decoration: none;
  color: black;
  padding-top: 10px ;
  padding-bottom: 10px;
  cursor: pointer;
}



@media (max-width: 800px){
  .sidebar{
    position: relative;
    left: calc(50vw - 170px);
  }
}

@media (max-width: 1500px) {
  .sidebar{
    margin: 20px 20px 100px;
  }
}


@media (max-width: 340px){
  .sidebar{
    position: relative;
    left: 0;
  }
}





