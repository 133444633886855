.album{
    font-family: "Varela" , sans-serif;
    color: #a7a4a4;
    font-size: 10px;
    margin: 2px;
}

.AllImagesContainer{
    display: inline-block;
    height: auto;
    width: auto;
    margin: 20px;
    overflow: hidden;
    cursor: pointer;
}

.AlbumImg{
    max-height: 250px;
    overflow: hidden;
    max-width: calc(100vw - 45px);
    border-radius: 10px;
}