/* LOADER */

#loader {
    position: relative;
    margin: 10% auto calc(50vh - 120px);
    border: 16px solid lightgrey;
    border-radius: 50%;
    border-top: 16px solid #8b0000;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
