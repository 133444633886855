.top {
  height: 50px;
  top: 0;
  display: flex;
  align-items: center;
  font-family: "Josefin Sans", sans-serif;
  z-index: 999;
  background: darkred;
  padding: 40px;
  color: white;
}
.link {
  display: inline;
  position: relative;
  overflow: hidden;
  opacity: .9999;
}
.link:after {
  top: 25px;
  content: "";
  position: absolute;
  z-index: -1;
  right: 0;
  width: 0;
  background: white;
  height: 2px;
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.link:hover:after,
.link:focus:after,
.link:active:after {
  left: 0;
  right: auto;
  width: 100%;
}

.topLeft,
.topRight {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topCenter {
  flex: 6;
}

.topIcon {
  font-size: 20px;
  margin-right: 10px;
  color: #444;
  cursor: pointer;
}

.topImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.topList {
  display: flex;
  justify-content: center;
  margin: 0;
  list-style: none;
}

.topListItem {
  margin-right: 20px;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
}

@media (max-width: 400px) {

  .topListItem{
    font-size: 13px;
  }
  .link:after{
    top:17px;
    height: 1px;
  }

}