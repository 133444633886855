.not-found h1, .not-found h2{
    font-family: "Varela" , sans-serif;
    text-align: center;
}

.not-found h1{
    margin-top: 60px;
}


.not-found + .footer{
    bottom: 0;
    position: fixed;
    width: 100%;
}

@media (max-width: 400px) {
    .not-found + .footer{
        width: auto;
    }
}